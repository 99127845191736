<template>
  <h1 class="text-h1 mb-24">
    <span v-if="getAllCases.length > 0">{{ getAllCases.length }} cases</span>
    <span v-else>Cases</span>
  </h1>
  <div v-if="getAllCases.length > 0">
    <MainCase
      v-for="(caseBlock, index) in getAllCases"
      :key="caseBlock.oid"
      :case-block="caseBlock"
      :index="index + 1"
      @openShareModal="openShareModalWindow"
      @openBuyPlanModal="needToBuyPlan = true"
      @emptyCase="checkEmptyCase"
    />
  </div>

  <BuyPlanModal v-if="needToBuyPlan" @closeModal="needToBuyPlan = false" />
  <ShareModal :caseId="caseForSharingId" v-if="openShareModal && !openModal" />
  <SendModal
    v-if="emptyCases.length"
    title="All empty cases will be deleted"
    subtitle="Our service no longer allows you to have cases without searchings"
    confirmText="Delete them"
    @closeModal="deleteEmptyCases"
    @confirmAnswer="deleteEmptyCases"
  />
  <SubscribeModal v-if="openSubscribeModal">
    <template v-if="getSubscribe">
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">Your Gamayun subscription is invalid</p>
      <p class="mb-24">You need to purchase a license to use these features.</p>
    </template>
    <template v-else>
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">You have run out of SL points</p>
      <p class="mb-24">You need to buy an additional package on the plan page.</p>
    </template>
  </SubscribeModal>
</template>

<script>
import MainCase from '@/components/main/Case';
import BuyPlanModal from '@/components/app/BuyPlanModal';
import ShareModal from '@/components/app/ShareModal';
import SendModal from '@/components/UI/SendModal';
import SubscribeModal from '@/components/app/SubscribeModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    MainCase,
    BuyPlanModal,
    ShareModal,
    SendModal,
    SubscribeModal
  },
  data () {
    return {
      needToBuyPlan: false,
      caseForSharingId: null,
      openAllCasesModal: false,
      emptyCases: []
    };
  },
  created () {
    this.setAllCases();
  },
  updated () {
    if (this.getAllCases.length <= 0) this.setAddNewCase();
  },
  computed: {
    ...mapGetters(['getAllCases', 'openShareModal', 'openSubscribeModal', 'getSubscribe'])
  },
  methods: {
    ...mapActions(['setAllCases', 'setRemoveCase', 'setLoaderBig', 'setAddNewCase']),

    openShareModalWindow (caseId) {
      this.$store.commit('setOpenShareModal', true);
      this.caseForSharingId = caseId;
    },
    checkEmptyCase (val) {
      this.emptyCases.push(val);
    },
    async deleteEmptyCases () {
      this.emptyCases.forEach(targetCase => { this.setRemoveCase(targetCase); });
      this.emptyCases = [];
      await this.$store.dispatch('checkAccount');
      if (this.getAllCases.length <= 0) this.setAddNewCase();
    }
  }
};
</script>
