<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarMain />
    </template>
    <template v-slot:content>
      <MainCases />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarMain from '@/viewsNew/SidebarMain';
import MainCases from '@/components/main/Cases';

export default {
  components: {
    Layout,
    SidebarMain,
    MainCases
  }
};
</script>
