<template>
  <router-link
    :to="activeRoute"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    class="d-flex align-center jc-space-between text-base transition bg-body_hover color-link_hover px-24 py-4"
  >
    <div class="text-overflow mr-30">{{ linkTitleToggle }}</div>
    <div class="no-wrap">{{ date(link.crtunix) }}</div>
  </router-link>
</template>

<script>
import { dateFormat } from '@/utils/helpers';

export default {
  props: ['link', 'itemRoute'],
  methods: {
    date (val) {
      return dateFormat(val);
    }
  },
  computed: {
    linkTitleToggle () {
      if (this.link.tag) return this.link.tag;
      else {
        const linkTitle = this.link.title.replace('{middle}', ' & ').replace('{end}', "'s connections");
        return linkTitle;
      }
    },
    activeRoute () {
      let itemRoute = '';
      if (this.itemRoute) {
        itemRoute = this.itemRoute;
        const matches = this.itemRoute.match(/\{[^}]+}/g);
        matches.forEach(m => {
          const match = m.replace('{', '').replace('}', '');
          itemRoute = itemRoute.replace(m, this.link[match]);
        });
      }

      return itemRoute;
    }
  }
};
</script>
