<template>
  <Waypoint
    :tag="'div'"
    class="bs-default mb-30"
    :disableCssHelpers="true"
    @change="waypointMethod"
  >
    <div class="p-relative bg-white br-top-left-5 br-top-right-5 p-24">
      <div
        v-if="editPanel"
        class="p-absolute z-2 top-0 left-0 right-0 bottom-0 d-flex align-center jc-space-between p-24"
      >
        <div class="p-relative">
          <input
            ref="input"
            type="text"
            v-model="caseName"
            placeholder="Enter case name"
            class="case-name input bg-blue w-450px pr-170"
            @keyup.enter="editCase"
          />
          <span class="p-absolute top-0 right-14px bottom-0 lh-48 fs-14 fw-400 color-text-lighter">
            <span :class="{ 'color-warning': caseName.length > limit }">{{ caseName.length }}</span> / {{ limit }} would be visible
          </span>
        </div>
        <div class="d-flex">
          <button @click="editPanel = false" class="mr-12">
            <Icon name="cancel" />
          </button>
          <button class="case-editing-ok" @click="editCase" v-if="caseName.trim() !== '' && caseName.length >= 1">
            <Icon name="ok" />
          </button>
        </div>
      </div>
      <div
        class="d-flex align-center jc-space-between"
        :class="{ 'opacity-0': editPanel }"
      >
        <router-link
          :to="'/case/' + caseBlock.oid"
          class="d-flex align-center"
        >
          <div class="br-circle bg-link color-white text-center text-h3 w-48px lh-48 mr-16">{{ index }}</div>
          <Tooltip
            :text="caseBlock.tag"
            :limit="26"
            tooltipWidth="350px"
            className="fs-20 fw-600 color-text-darker lh-24"
          />
        </router-link>
        <div class="d-flex align-center">
          <div class="text-base ml-16">
            {{ searches.length }} profiles
          </div>
          <div class="text-base ml-16">
            {{ connections.length }} connections
          </div>
          <div class="text-base ml-16">
            {{ date(caseBlock.crtunix) }}
          </div>
          <div class="ml-16">
            <button
              class="text-link fw-400 test-share"
              @click.prevent="shareCase"
            >
              <Icon name="share" class="mr-8" />
              Share
            </button>
          </div>
          <div class="ml-16">
            <button @click.prevent="openEditPanel">
              <Icon name="edit" class="icon-hover" />
            </button>
          </div>
          <div class="ml-16">
            <button @click.prevent="setCaseToRemove(caseBlock)">
              <Icon name="trash" class="icon-hover" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="searches.length || connections.length"
      class="bg-form br-bottom-left-5 br-bottom-right-5 py-20">
      <CaseLink
        v-for="link in showSearches"
        :key="link.rid"
        :link="link"
        item-route="/case/{fid}/{rid}"
      />
      <CaseLink
        v-for="link in showConnections"
        :key="link.id"
        :link="link"
        item-route="/case/{case_id}/{search_id}/{id}"
      />
      <div
        v-if="allLinksLength > 3"
        class="px-24 py-4"
      >
        <a
          @click.prevent="toggleShowLinks"
          class="text-link fs-14"
        >{{ showAllLinks ? 'Hide' : 'More' }}</a>
      </div>
    </div>
  </Waypoint>
</template>

<script>
import Icon from '@/components/app/Icon';
import CaseLink from '@/components/main/CaseLink';
import { Waypoint } from 'vue-waypoint';
import { mapActions, mapGetters } from 'vuex';
import { dateFormat } from '@/utils/helpers';
import Tooltip from '@/components/UI/Tooltip';

export default {
  components: {
    Icon,
    CaseLink,
    Tooltip,
    Waypoint
  },
  emits: ['emptyCase'],
  props: ['caseBlock', 'index'],
  data () {
    return {
      showTooltip: false,
      showAllLinks: false,
      limit: 26,
      editPanel: false,
      caseName: '',
      emitSend: false
    };
  },
  computed: {
    ...mapGetters(['accountState', 'getSearches', 'getConnections']),

    searches () {
      return this.getSearches[this.caseBlock.oid] || [];
    },
    connections () {
      return this.getConnections[this.caseBlock.oid] || [];
    },
    allLinksLength () {
      return this.searches.length + this.connections.length;
    },
    showSearches () {
      if (!this.showAllLinks) return this.searches.slice(0, 3);
      else return this.searches;
    },
    showConnections () {
      if (!this.showAllLinks) {
        if (this.searches.length > 3) return [];
        else {
          const connectionsLength = 3 - this.searches.length;
          return this.connections.slice(0, connectionsLength);
        }
      } else return this.connections;
    }
  },
  methods: {
    ...mapActions(['setCurrentSearches', 'setCurrentConnections', 'setCaseToRemove', 'setEditCase']),

    date (val) {
      return dateFormat(val);
    },
    toggleShowLinks () {
      this.showAllLinks = !this.showAllLinks;
    },
    async waypointMethod (waypointState) {
      if (waypointState.going === 'IN') {
        await this.setCurrentSearches(this.caseBlock.oid);
        await this.setCurrentConnections(this.caseBlock.oid);
        if (!this.emitSend) {
          if (!this.searches.length && !this.connections.length) this.$emit('emptyCase', this.caseBlock);
          this.emitSend = true;
        }
      }
    },
    shareCase () {
      if (this.accountState.subscription.code !== 'base_month' && this.accountState.subscription.code !== 'base_year') {
        this.$emit('openBuyPlanModal');
        return;
      }
      this.$emit('openShareModal', this.caseBlock.oid);
    },
    openEditPanel () {
      this.caseName = this.caseBlock.tag;
      this.editPanel = true;
      setTimeout(() => { this.$refs.input.focus(); }, 0);
    },
    editCase () {
      const caseInfo = {
        fid: this.caseBlock.oid,
        tag: this.caseName,
        oldTag: this.caseBlock.tag
      };
      this.setEditCase(caseInfo);
      this.editPanel = false;
    }
  }
};
</script>
